import React, { useEffect, useState } from 'react'
function Main () {
  const [scrollPage, setScrollPage] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
      setScrollPage(window.scrollY / window.innerHeight);
      // console.log(window.scrollY, Math.floor(window.scrollY / window.innerHeight));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="app">
      <div className="hero">
        <div className="center-container no-max-width">
          <div className="content">
            <div className="text-center">
              <a className="header-logo-link" href="/">
                <img className="header-logo" src="mad-raven-games-wide-logo-purple.png" alt="Mad Raven Games logo" />
              </a>
            </div>
            <h1 className="text-center mb-none">
              <span className="small">
                <span className="blink-in o-0" style={{animationDelay: '.5s', animationDuration: '4s', animationFillMode: 'forwards', animationIterationCount: 'infinite'}}>CREATING </span>
                <span className="blink-in o-0" style={{animationDelay: '.4s', animationDuration: '6s', animationFillMode: 'forwards', animationIterationCount: 'infinite'}}>YOUR</span>
              </span>
              <span className="big stamp o-0" style={{animationDelay: '1s', animationDuration: '.1s', animationFillMode: 'forwards'}}>NIGHTMARE</span>
            </h1>
            <a className="np-logo-link" rel="noreferrer" href="https://www.nightmarepartygame.com" target="_blank">
              <img className="np-logo" src="nightmare-party-logo-drawn-dark-bg-sm.png" alt="Nightmare Party logo" />
            </a>
            <h2 className="text-center mb-none" style={{ marginTop: '24px' }}>
              <span className="dark-bg med">
                LOOKING FOR TALENT
              </span>
            </h2>
            <div className="text-center" style={{ color: '#fff' }}>
              <span>Environment & prop artists wanted</span>
              <br />
              <span style={{ fontSize: '.8em', color: 'rgba(255, 255, 255, .7)' }}>
                <i className="fa fa-solid fa-money" style={{ marginRight: '4px' }}></i>
                Pay per project / model
                <i className="fa fa-solid fa-money" style={{ marginLeft: '4px' }}></i>
                <br />
                <i className="fa fa-solid fa-heart" style={{ marginRight: '4px' }}></i>
                <strong>looking for long-term relationships</strong>
                <i className="fa fa-solid fa-heart" style={{ marginLeft: '4px' }}></i>
                <br />
                <span style={{ fontSize: '.8em', display: 'block', marginTop: '-4px', color: 'rgba(255, 255, 255, .5)' }}>
                  not that kind 😂
                </span>
              </span>
            </div>
            {/*<div className="text-center buttons">*/}
              {/*<a href="https://store.steampowered.com/app/1644910/Nightmare_Party/" target="_blank" rel="noreferrer" className="button"><i className="fa fa-brands fa-steam"></i>Wishlist on Steam</a>*/}
              {/*<a href="https://www.youtube.com/watch?v=yG42hk2Szps" target="_blank" rel="noreferrer" className="button"><i className="fa fa-brands fa-youtube"></i>Watch trailer</a>*/}
              {/*<a href="https://discord.gg/HnsHDzFpUs" target="_blank" rel="noreferrer" className="button"><i className="fa fa-brands fa-discord"></i>Join Discord</a>*/}
              {/*<a href="#faq" className="button"><i className="fa fa-solid fa-question"></i>FAQ</a>*/}
            {/*</div>*/}
            {/*<div className="video-container">*/}
            {/*  <div className='embed-container'>*/}
            {/*    <iframe title="Video" src='https://www.youtube.com/embed/yG42hk2Szps' frameBorder="0" scrolling="0" allowFullScreen="1" />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="text-center" style={{ position: 'relative', zIndex: 2 }}>
          <div className="text-center bouncing" style={{ background: 'rgba(255,255,255,.4)', borderRadius: '50%', width: '32px', height: '32px', display: 'inline-block' }}>
            <i className="fa fa-solid fa-arrow-down" style={{ fontSize: '1.2rem', color: 'rgba(0,0,0,.8)', paddingTop: '5px' }}></i>
          </div>
        </div>
      </div>

      <div className="overlay grunge-border"></div>

      <div className="section-bg light pt-xl pb-xl text-center">
        <div className="center-container">
          <h2 className="text-center grunge-h">
            Mad Raven Games
          </h2>
          <h2 className="text-center mb-l">
            <span className="small">Who are you?</span>
            <span className="big">Indie from Budapest, Hungary</span>
          </h2>
          <p>
            <span className="first-letter">H</span>ello! I'd be glad if I could start describing Mad Raven Games like
            "we are a successful indie studio with this and that achivements"; I can't... yet. But I'm working on it. 🙂
          </p>
          <p className="text-left">
            Curretly me, Balazs Varga is the only team member and I'd really like to change this in the near future. My main
            goal currently is to finish and release Nightmare Party and I'm looking for people who can help me to achieve that.
          </p>
          <p className="text-left">
            If you are interested in creating games, especially 3D environments and props, please scroll down to see open
            "positions". Keep in mind that these are freelancer opportunities, not full-time jobs.
          </p>
        </div>
      </div>

      <div className="overlay grunge-border"></div>

      <div className="section-bg second-page h-f v-f bg-f" style={{ padding: '0' }}>
        <div className="center-container">
          <div className="content" style={{ background: 'rgba(0,0,0,.9)' }}>
            <h2 className="text-center mb-none" style={{ textShadow: '1px 1px 3px #000', paddingTop: '48px' }}>Nightmare Party and the talent I need</h2>
            <p style={{ textShadow: '1px 1px 3px #000'}}>
              <span className="first-letter">N</span>ightmare Party is a multiplayer co-op horror survival FPS. The scope
              of the project changed quite a bit in the past year and that's the reason why I'm looking for people to join me
              to create a fun-to-play nightmare experience. 👻
            </p>
            <p style={{ textShadow: '1px 1px 3px #000' }}>
              I'm looking for long-term connections, not particularly interested in smaller contributions. The environment
              is (more like will be) a post-soviet island and there is a lot to be done (🏠houses, 🏛️buildings, 🛣️roads, 🪦props).
            </p>
            <p style={{ textShadow: '1px 1px 3px #000' }}>
              Running on Unreal Engine 5, the possibilities are almost endless - the only thing I want to mention is that
              this is a game, not a tech demo, so optimization is necessary and Nanite is not a solution to everything.
            </p>
            <p style={{ textShadow: '1px 1px 3px #000' }}>
              In the past 3 years I learned how to create 3D models, materials and I have varying knowledge about a lot of things in Unreal Engine as
              I had to do everything myself, including C++, sound design, animations and modeling / texturing. I'm not a professional but I have
              17 years of experience as a software developer, currently working as a tech lead in a different field. I'd say coding is the easiest thing for me,
              followed by sound design (I play the guitar for 18 years).
            </p>
            <p style={{ textShadow: '1px 1px 3px #000' }}>
              My point is that I'm not a newbie, I understand the technical difficulties and I recognize bad / good topology. 🙂
            </p>
            <p style={{ textShadow: '1px 1px 3px #000' }}>
              My original plan was to make everything myself and buy assets if I need them, but I don't really have time to do *everything* and
              there is a limit what you can achieve with pre-made assets.
            </p>
            <p style={{ textShadow: '1px 1px 3px #000' }}>
              I think the time has come to build a team because I don't want to be a lone wolf anymore and I like to work
              in a team. Even if this team consists of freelancers across the globe.
            </p>
            <p style={{ textShadow: '1px 1px 3px #000', paddingBottom: '64px' }}>
              If you are interested, check out the "looking for talent" section below. I can't offer you pension, insurance and a full-time job but if you want
              to build your portfolio, try yourself out in a real project and want to see your assets in a game and also earn money with it, I'm quite sure we can
              come to an agreement.
            </p>
          </div>
        </div>
      </div>

      <div className="overlay grunge-border"></div>

      <div className="section-bg light pt-xl pb-xl text-center">
        <div className="center-container">
          <h2 className="text-center grunge-h">
            Looking for talent
          </h2>
          <h2 className="text-center mb-l">
            <span className="small">Do you like creating environments?</span>
            <span className="big">Environment artist</span>
          </h2>
          <p className="mb-l">
            <h3>You are the best person for this role if:</h3>
            <ul className="text-left">
              <li>you can understand and express yourself in English or Hungarian (calls aren't mandatory)</li>
              <li>you have experience in low / mid poly modeling</li>
              <li>you can show me something you created (game ready and with wireframe)</li>
              <li>you don't just apply bevel & subdiv on everything and call it a day</li>
              <li>you only use N-gons intentionally, not accidentally</li>
              <li>you are willing to do research, look up abandoned places, gather your references and talk about them</li>
              <li>you are capable of designing and modeling coherent environments</li>
              <li>you are not driven by your ego, you can take feedback and adjust to what the game needs</li>
              <li>you have experience in creating PBR materials</li>
              <li>you understand how UV tiling works and can create models according to that (same texel density most of the time)</li>
              <li>you love brainstorming, there are a lot of open questions yet - your voice will be heard</li>
              <li>you want to be a partner in creating something amazing, not just an employee / contractor who doesn't give a fuck</li>
              <li>you are efficient in Blender, Maya or Max, I'm not looking for absolute beginners (preferably better than me)</li>
              <li>you deliver quality, game ready work, no bullshit excuses if we agree on a deadline</li>
            </ul>

            <h3>What I can offer in return</h3>
            <ul className="text-left">
              <li>payment for your work, per project / model (what we agree on)</li>
              <li>you can shape the game</li>
              <li>you can share your work in your portfolio</li>
              <li>no rush, no brutal deadlines</li>
              <li>a free copy of the game</li>
              <li>your name in the game credits ❤️</li>
            </ul>
          </p>

          <h2 className="text-center mb-l">
            <span className="small">Do you like creating detailed assets?</span>
            <span className="big">Prop artist</span>
          </h2>
          <p className="mb-l">
            <h3>You are the best person for this role if:</h3>
            <ul className="text-left">
              <li>you can understand and express yourself in English or Hungarian (calls aren't mandatory)</li>
              <li>you have experience in low / mid poly modeling</li>
              <li>you can show me something you created (game ready and with wireframe)</li>
              <li>you don't just apply bevel & subdiv on everything and call it a day</li>
              <li>you only use N-gons intentionally, not accidentally</li>
              <li>you are willing to do research, gather your references and talk about how your props will fit in the environment</li>
              <li>you are not driven by your ego, you can take feedback and adjust to what the game needs</li>
              <li>you have experience in creating PBR materials and you can export packed PBR maps ready to be used in Unreal Engine</li>
              <li>you want to be a partner in creating something amazing, not just an employee / contractor who doesn't give a fuck</li>
              <li>you are efficient in Blender, Maya or Max, I'm not looking for absolute beginners (preferably better than me)</li>
              <li>you deliver quality, game ready work, no bullshit excuses if we agree on a deadline</li>
            </ul>

            <h3>What I can offer in return</h3>
            <ul className="text-left">
              <li>payment for your work, per prop pack / model (what we agree on)</li>
              <li>you can shape the game</li>
              <li>you can share your work in your portfolio</li>
              <li>no rush, no brutal deadlines</li>
              <li>a free copy of the game</li>
              <li>your name in the game credits ❤️</li>
            </ul>
          </p>

          <h3>
            I know this is not your dream AAA job, but this is not a huge studio, I have to cook with what I have. And that's mostly me and my salary. 🙂
          </h3>

          <p style={{ fontSize: '1.6rem' }}>
            If you are interested in any of the positions above, please send an email to <a href="mailto:contact@madravengames.com">contact@madravengames.com</a> or contact me on <a href="https://discordapp.com/users/427540378003046401">
              <i className="fa fa-brands fa-discord" aria-hidden="true"></i>
              Discord
            </a>
          </p>
        </div>
      </div>

      <div className="overlay grunge-border"></div>

      <div className="section-bg footer">
        <div className="center-container text-center">
          <img className="mad-raven-games-logo" src="mad-raven-games-wide-logo-grey.png" alt="Mad Raven Games logo"/>
          <p>
            Copyright Mad Raven Games.<br />
            All rights reserved. <br />
            <a href="mailto:contact@madravengames.com">contact@madravengames.com</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Main